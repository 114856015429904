import React from "react"
import { Link } from "gatsby"
import footerStyle from "./style/footer.module.css"

export default () => {

    const date = new Date(Date.now())
    const cp = `&copy; ${date.getFullYear()} Ben Dod.`

    return (
        <footer className={`${footerStyle.container} text-sans-serif-display`}>
            <div className={footerStyle.linktablebox}>
                <p className={footerStyle.statement} dangerouslySetInnerHTML={{__html: cp}}/>
                <table className={footerStyle.linktable}>
                    <tbody>
                        <tr>
                            <td><Link to='/'>
                                <p className={footerStyle.statement}>Home</p>
                            </Link></td>
                            <td><Link to='/blog'>
                                <p className={footerStyle.statement}>Blog</p>
                            </Link></td>
                            <td><Link to='/about'>
                                <p className={footerStyle.statement}>About</p>
                            </Link></td>
                            <td><Link to='/about#contact'>
                                <p className={footerStyle.statement}>Contact</p>
                            </Link></td>
                        </tr>   
                    </tbody>
                </table>
            </div>
        </footer>
    )
}
