import React from "react"
import style from "./style/clicklogo.module.css"
import BigB from "../images/SVG/new_logo.svg"
import { Link } from "gatsby"

export default (props) => {

    const imgStyle = {
        width: props.width ? props.width : null,
    };

    const divStyle = {
        margin: props.margin ? props.margin : null,
    } 

    const logoImg = <img src={BigB} className={style.bigb} style={imgStyle}/>

    return (
        props.to 
        ? <Link 
            to={props.to ? props.to : null} 
            className={`${style.container} ${props.className}`}
            style={divStyle}
        >   
            {logoImg}
        </Link>

        :

        <div
        className={`${style.container} ${props.className}`}
        style={divStyle}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
	>
            {logoImg}
        </div>
    )
}
