import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import buttonStyle from "./style/navbutton.module.css"


export default (props) => {

    useEffect(() => {
        // TODO: regex looks nasty
        if (window.location.pathname.replace(/^\//,'').replace(/\/$/) == props.to.replace(/^\//,'').replace(/\/$/)) {
            setPageState(buttonStyle.activepage);
        } else if (window.location.pathname.match(props.to.replace(/^\//,''))) {
			setPageState(buttonStyle.childpage);
		} else 
            setPageState(null);
    })

    const [pageState, setPageState] = useState(null);

    const thisStyle = {
        color: props.color,
    }
    
    return (
        <Link to={props.to}>
            <div className={`${buttonStyle.box} ${pageState}`} style={thisStyle}>
                <p className={`${buttonStyle.text} `}>{props.children}</p>
                <div className={`${buttonStyle.pagestatebox}`}>
                    <div className={`${buttonStyle.pagestate}`}></div>
                </div>
            </div>
        </Link>
        
    );
}
