import React, { useEffect, useState }  from "react"
import NavButton from "./ui/basic/navbutton"
import WhiteLogo from "../images/bendod_white.png"
import ClickLogo from "./clicklogo"
import Waves from "../images/polywaves_plain.svg"
import navStyle from "./style/navbar.module.css"

export default () => {

    const buttons = [
        <div className={navStyle.buttonbox}>
            <NavButton to="/blog" key="navbutton-blog">Blog</NavButton>
            <NavButton to="/projects" key="navbutton-projects">Projects</NavButton>
            <NavButton to="/about" key="navbutton-about">About</NavButton>
        </div>
    ]

    const logo = <ClickLogo width="80px" margin="15px" to='/'/>

    return (
        <nav className={`${navStyle.bar} text-sans-serif-display`}>
            {logo}
            {buttons}
        </nav>
    )
}
