import React from "react"
import frameStyle from "./style/frame.module.css"
import NavBar from "./navbar"
import Footer from "./footer"
import { Helmet } from "react-helmet"

export default (props) => {

    // polywaves inline
   const wavy = (
      <div>
            <svg id="polywaves">
            <defs
     id="defs3807">
    <clipPath
       id="clipPath5973"
       clipPathUnits="userSpaceOnUse">
      <rect
         style={{"opacity": "1", "fillOpacity": "1", "stroke": "none", "strokeWidth": "0.276611"}}
         id="rect5975"
         width="80"
         height="80"
         x="56.393871"
         y="35.012306" />
    </clipPath>
    
  </defs>

  <mask id="headline-mask">
   <text x="10" y="10" class="headline text-sans-serif text-bold" style={{fill: "white"}}>Hello</text>
  </mask>

  <g
     id="layer1" mask="url(#headline-mask)">
    <rect
       y="0"
       x="-187.47618"
       height="4"
       width="128"
       id="rect4381"
       style={{"fill": "#864d95", "strokeWidth": "0.264583"}} />
    <use
       height="100%"
       width="100%"
       id="use5930"
       stroke="#f27053"
       fill="#f27053"
       transform="matrix(0.99427179,0.10843681,-0.10857083,0.99550066,70.517296,64.167957)"
       xlinkHref="#path4958"
       y="0"
       x="0" />
    <g
       transform="translate(-56.393871,-35.012306)"
       clip-path="url(#clipPath5973)"
       id="g5966">
      <path
         id="path4958"
         d="m -11.096564,86.177099 26.97404,-54.199232 53.290878,1.966195 25.957779,-48.868265 50.699647,-2.809558 26.12421,44.492507 z"
         style={{"opacity": "1", "fillOpacity": "1", "stroke": "none", "strokeWidth": "0.264583px",
        "strokeLinecap": "butt", "strokeLinejoin": "miter", "strokeOpacity": "1"}}
         />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(1.0115776,-0.04912913,0.0477306,0.9827816,-2.5724078,4.1562678)"
         fill="#f79c5d"
         stroke="#f79c5d"
         id="use5928" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.99055564,0.16378323,-0.16320699,0.98707056,6.6311772,3.7800929)"
         fill="#ed4a64"
         stroke="#ed4a64"
         id="use5932" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.97714375,0.16787423,-0.16781758,0.97681399,7.7625287,11.395734)"
         fill="#e74270"
         stroke="#e74270"
         id="use5934" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.95062463,0.32383754,-0.32024573,0.94008087,15.226783,9.0703174)"
         fill="#e13aa6"
         stroke="#e13aa6"
         id="use5936" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.92435871,0.3735347,-0.36807365,0.91084466,18.857592,14.103007)"
         fill="#da33bf"
         stroke="#da33bf"
         id="use5938" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.89907616,0.41223751,-0.41055418,0.89540487,22.222734,19.422041)"
         fill="#d22dd1"
         stroke="#d22dd1"
         id="use5940" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.86452961,0.48260112,-0.48293288,0.86512392,27.343703,23.008666)"
         fill="#a42fc1"
         stroke="#a42fc1"
         id="use5942" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.84520964,0.55958617,-0.54983332,0.83047874,31.17135,26.278954)"
         fill="#7131b0"
         stroke="#7131b0"
         id="use5944" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.76969697,0.63979404,-0.65037403,0.7824251,40.26152,29.806863)"
         fill="#5b33a1"
         stroke="#5b33a1"
         id="use5946" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.74213475,0.6879121,-0.67638995,0.7297044,43.189837,35.809546)"
         fill="#353492"
         stroke="#353492"
         id="use5948" />
      <use
         height="100%"
         width="100%"
         x="0"
         y="0"
         xlinkHref="#path4958"
         transform="matrix(0.71313266,0.70338475,-0.71041008,0.72025535,46.51351,42.578355)"
         fill="#343d83"
         stroke="#343d83"
         id="use5950" />
    </g>
  </g>
            </svg>
        </div>
   )

   const navBar = (props.nav === undefined)
   ? <NavBar/> 
   : (props.nav == true)
   ? <NavBar/>
   : null

   const gutterClass = (props.gutter === undefined)
   ? frameStyle.container
   : (props.gutter == true)
   ? frameStyle.container
   : '';

   return (
      <div className={`${frameStyle.root}`} style={{position: "relative", minHeight: "100vh"}}>
         <Helmet>
            <title>Ben Dod</title>
         </Helmet>
         <div style={{paddingBottom: "120px"}}>
            {navBar}
            <div className={gutterClass}>
               <div className={`${frameStyle.main}`}>
                  {props.children}
               </div>
            </div>
         </div>
         <Footer></Footer>
      </div> 
      
      
   );
}